import useSWR from "swr";
import { baseUrl } from "../const/const";

const fetcher = (input: RequestInfo) =>
  fetch(input, { headers: { Token: "138bbdb71e2820bb41e5a7a376b0a889" } }).then(
    (res) => res.json()
  );

export const useUsers = () => {
  const { data, error } = useSWR(`${baseUrl}v2/admin/user/0/100`, fetcher);

  return {
    allUsers: data,
    isLoading: !error && !data,
    isError: error,
  };
};
