import useSWR from "swr";
import { baseUrl } from "../const/const";
import {useState} from "react";

const fetcher = (input: RequestInfo) => fetch(input).then((res) => res.json());

export const usePassword = (mounted: boolean, username: string) => {
  const { data, error } = useSWR(mounted ?
    `${baseUrl}v2/admin/user/password/${username}`: null,
    fetcher
  );


  return { data }
};
