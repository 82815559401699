import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import "./Menu.css";

interface AppPage {
  url: string;
  title: string;
}

const appPages: AppPage[] = [
  // {
  //   title: "Gebruikers",
  //   url: "/users",
  // },
  // {
  //   title: "Uitloggen",
  //   url: "/logout",
  // },
];

const Menu: React.FC = () => {
  const loggedIn = localStorage.getItem("loggedIn") !== null;

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList className="bg-primary-light h-screen">
          <IonListHeader className="text-white text-2xl">
            DayClocks Admin
          </IonListHeader>

          {loggedIn &&
            appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    // className={
                    //   location.pathname === appPage.url
                    //     ? "selected"
                    //     : "bg-primary-light"
                    // }
                    className="mt-5"
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    {appPage.title}
                  </IonItem>
                </IonMenuToggle>
              );
            })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
