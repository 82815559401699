import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {usePassword} from "../../hooks/usePassword";

const Password: React.FC = () => {
  const username = localStorage.getItem('username') as string;
  const [mounted, setMounted] = useState(false)
  const { data } = usePassword(mounted, username)

  useEffect(() => {
    setMounted(true)
  }, [])


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
        <IonTitle>Wachtwoord</IonTitle>
      </IonHeader>


      <IonContent fullscreen>
        <h3>Nieuwe wachtwoord is naar de klant verstuurd</h3>
      </IonContent>
    </IonPage>
  );
};

export default Password;
