import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addUser } from "../api/users";
import { useState } from "react";
import { useHistory } from "react-router";

const User: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  const [language, setLanguage] = useState("");
  const history = useHistory();

  const add = async () => {
    const user = {
      username,
      email,
      language,
      surname: name,
      dayclockType: type,
    };

    addUser(user);
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>Add</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="p-5">
          <div className="flex flex-col">
            <IonLabel className="font-bold">Name</IonLabel>
            <IonInput
              className="border-2 border-blue-200 rounded-md"
              onIonChange={(e) => setName(e.detail.value!)}
            ></IonInput>
          </div>
          <div className="flex flex-col mt-4">
            <IonLabel className="font-bold">Email</IonLabel>
            <IonInput
              className="border-2 border-blue-200 rounded-md"
              onIonChange={(e) => setEmail(e.detail.value!)}
            ></IonInput>
          </div>
          <div className="flex flex-col mt-4">
            <IonLabel className="font-bold">Username</IonLabel>
            <IonInput
              className="border-2 border-blue-200 rounded-md"
              onIonChange={(e) => setUsername(e.detail.value!)}
            ></IonInput>
          </div>
          <div className="mt-5 flex flex-col">
            <IonLabel className="font-bold">DayClock</IonLabel>
            <IonRadioGroup
              value="10"
              onIonChange={(e) => setType(e.detail.value!)}
            >
              <IonItem>
                <IonLabel>DayClock 7 </IonLabel>
                <IonRadio value="7"></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>DayClock 10 </IonLabel>
                <IonRadio value="10"></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </div>

          <div className="mt-5 flex flex-col">
            <IonLabel className="font-bold">Language</IonLabel>
            <IonRadioGroup
              value="nl"
              onIonChange={(e) => setLanguage(e.detail.value!)}
            >
              <IonItem>
                <IonLabel>Dutch </IonLabel>
                <IonRadio value="nl">Dutch</IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>English </IonLabel>
                <IonRadio value="en"></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>German </IonLabel>
                <IonRadio value="de"></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>French </IonLabel>
                <IonRadio value="fr"></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </div>
          <IonButton className="mt-10" expand="block" onClick={add}>
            Add
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default User;
