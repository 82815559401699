import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fetchSearch } from "../api/users";
import { useUsers } from "../hooks/useUsers";
import { User } from "./detail/Detail";
import "./Users.css";
import { IoExitOutline, IoAddCircleOutline } from "react-icons/io5";

const Users: React.FC = () => {
  const history = useHistory();
  const { allUsers } = useUsers();
  const [users, setUsers] = useState(allUsers);

  const showDetail = (username: string) => {
    localStorage.setItem("username", username);
    history.push(`/users/detail/${username}`);
  };

  const searchUsers = async (search: string) => {
    if (search.length > 0) {
      const result = await fetchSearch(search);
      setUsers(result);
    } else {
      setUsers(allUsers);
    }
  };

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton routerLink={"/users/add"}>
              <IoAddCircleOutline className="text-2xl" />
            </IonButton>
            <IonButton routerLink={"/logout"}>
              <IoExitOutline className="text-2xl" />
            </IonButton>
          </IonButtons>

          <IonTitle>Gebruikers</IonTitle>
        </IonToolbar>
        <IonSearchbar
          onIonChange={(e) => searchUsers(e.detail.value!)}
        ></IonSearchbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Gebruikersnaam
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="inline-block w-36">Naam</div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Aangemaakt
                </th>
              </tr>
            </thead>
            <tbody>
              {users &&
                [...users].map((user: User, index: number) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer hover:bg-gray-100"
                    onClick={() => showDetail(user.username)}
                  >
                    <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {user.username}
                    </td>
                    <td className="px-6 py-4">{user.name}</td>
                    <td className="px-6 py-4">
                      {user.status ? "Niet Actief" : "Actief"}
                    </td>
                    <td className="px-6 py-4">{user.email}</td>
                    <td className="px-6 py-4">
                      {user.creationDate?.substring(0, 10)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Users;
