import useSWR from "swr";
import { baseUrl } from "../const/const";

const fetcher = (input: RequestInfo) => fetch(input).then((res) => res.json());

export const useUser = (username: string) => {
  const { data, error } = useSWR(
    `${baseUrl}v2/admin/user/${username}`,
    fetcher
  );
  
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};
