import { baseUrl } from "../const/const";

export type User = {
  username: string;
  surname: string;
  email: string;
  dayclockType: string;
};

export const fetchSearch = (search: string) => {
  return fetch(`${baseUrl}v2/admin/user/search/${search}`).then((res) =>
    res.json()
  );
};

export const fetchDetail = (username: string) => {
  return fetch(`${baseUrl}v2/admin/user/${username}`, {}).then((res) =>
    res.json()
  );
};

export const saveUser = (user: User) => {
  const data = {};
  const token = localStorage.getItem("token");
  fetch(`${baseUrl}v2/user/${token}/save`, {
    method: "POST",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const addUser = (user: User) => {
  const token = localStorage.getItem("token");
  fetch(`${baseUrl}v1/user/${token}/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((response) => response.json());
};

export const activateUser = (username: string) => {
  fetch(`${baseUrl}v2/admin/user/status/${username}/active`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const deactivateUser = (username: string) => {
  fetch(`${baseUrl}v2/admin/user/status/${username}/delete`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};
