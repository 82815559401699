import { IonItem, IonLabel } from "@ionic/react";

export interface DetailItemProps {
  name: string;
  value: string;
}

const DetailItem: React.FC<DetailItemProps> = (
  detailItemProps: DetailItemProps
) => {
  const { name, value } = detailItemProps;

  return (
    <IonItem>
      <IonLabel className="font-bold">{name}</IonLabel>
      <IonLabel className="text-right">{value}</IonLabel>
    </IonItem>
  );
};

export default DetailItem;
