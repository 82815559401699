import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect } from "react";

const LogoutPage: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("loggedInUsername");
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>DayClock Admin</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="mt-28 mx-10 md:w-1/2 md:mx-auto">
          <div className="text-3xl font-bold">Uitgelogd</div>

          <p className="my-5">U bent nu uitgelogd.</p>
          <IonRouterLink href="/login">
            <IonButton>Inloggen</IonButton>
          </IonRouterLink>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LogoutPage;
