import {
  IonButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
} from "@ionic/react";
import { useState } from "react";
import DetailItem from "./DetaiItem";
import { useHistory } from "react-router";
import { activateUser, deactivateUser } from "../../api/users";

export interface User {
  username: string;
  email: string;
  name: string;
  creationDate: string;
  lastAccess: string;
  hasSpeech: boolean;
  dayclockType: string;
  dayclockVersion: string;
  password: string;
  status: boolean;
  count: {
    events: number;
  };
}

export interface DetailContentProps {
  user: User;
}

const DetailContent: React.FC<DetailContentProps> = (
  detailContentProps: DetailContentProps
) => {
  const history = useHistory();
  const { user } = detailContentProps;
  const [togglePassword, setTogglePassword] = useState(false);

  const {
    username,
    name,
    email,
    lastAccess,
    creationDate,
    dayclockType,
    dayclockVersion,
    password,
    count,
  } = user;

  const showPassword = () => {
    history.push(`/users/password`);
  };

  const activate = () => {
    if (user.status) {
      activateUser(username);
    } else {
      deactivateUser(username);
    }
    history.goBack();
  };

  const showScreenshot = () => {
    console.log("show screenshot");
  };

  return (
    <>
      <IonList className="mt=5 px-5">
        <IonItemDivider>
          <IonLabel>Gebruiker</IonLabel>
        </IonItemDivider>
        <DetailItem name="Gebruikersnaam" value={username} />
        <DetailItem name="Naam" value={name} />
        <DetailItem name="E-mail" value={email} />
        <DetailItem
          name="Laatst ingelogd"
          value={lastAccess?.substring(0, 10)}
        />
        <DetailItem name="Aangemaakt" value={creationDate?.substring(0, 10)} />

        <IonItem>
          <IonLabel className="font-bold">Wachtwoord</IonLabel>

          <IonButton
            className="mr-5"
            onClick={() => setTogglePassword(!togglePassword)}
          >
            tonen
          </IonButton>
          <div>
            {togglePassword ? (
              <IonLabel className="text-right w-32">{password}</IonLabel>
            ) : (
              <IonLabel className="text-right w-32">********</IonLabel>
            )}
          </div>
        </IonItem>
        {togglePassword && (
          <IonItem>
            <div className="flex-col w-full justify-end">
              <button
                onClick={copyNL}
                className="bg-blue-500 text-white p-1 m-1 rounded-md text-sm"
              >
                copy
              </button>
              <div id="text-nl">
                <p>{email}</p>
                <p>Hallo {name},</p>
                <p>
                  Gebruikersnaam: {username} <br />
                  Wachtwoord: {password}
                  <br />
                </p>
                <p>Met vriendelijke groeten,</p>
              </div>
            </div>
          </IonItem>
        )}

        {togglePassword && (
          <IonItem>
            <div className="flex-col w-full justify-end">
              <button
                onClick={copyDE}
                className="bg-blue-500 text-white p-1 m-1 rounded-md text-sm"
              >
                copy
              </button>
              <div id="text-de">
                <p>{email}</p>
                <p>Hallo {name},</p>
                <p>
                  Benutzername: {username} <br />
                  Kennwort: {password}
                  <br />
                </p>
                <p>Mit freundlichen Grüßen,</p>
              </div>
            </div>
          </IonItem>
        )}

        <IonItemDivider>
          <IonLabel>DayClock</IonLabel>
        </IonItemDivider>

        <DetailItem name="Type" value={dayclockType} />
        <DetailItem name="Versie" value={dayclockVersion} />

        <IonItemDivider>
          <IonLabel>Aantallen</IonLabel>
        </IonItemDivider>

        <DetailItem name="Activiteiten" value={count.events.toString()} />
      </IonList>

      <IonButton expand="block" className="m-5" onClick={showPassword}>
        Nieuw wachtwoord
      </IonButton>

      <IonButton expand="block" className="m-5" onClick={activate}>
        {user.status ? "Activeer" : "Deactiveer"}
      </IonButton>
    </>
  );
};

const copyDE = () => {
  const copyText: any = document.getElementById("text-de");
  navigator.clipboard.writeText(copyText?.innerText);
};
const copyNL = () => {
  const copyText: any = document.getElementById("text-nl");
  navigator.clipboard.writeText(copyText?.innerText);
};
export default DetailContent;
