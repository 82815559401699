import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useUser } from "../../hooks/useUser";
import DetailContent from "./DetailContent";

export interface User {
  username: string;
  email: string;
  name: string;
  creationDate: string;
  status: string;
}

const Detail: React.FC = () => {
  const { user } = useUser(localStorage.getItem("username") || "");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{user && user.username}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="md:hidden">
          <React.Suspense fallback={<p>Loading</p>}>
            {user && <DetailContent user={user} />}
          </React.Suspense>

          <div className="px-5 pt-10"></div>
        </div>
        <div className="hidden md:block md:w-3/5 mx-auto mt-10">
          {user && <DetailContent user={user} />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Detail;
