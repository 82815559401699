import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Users from "./pages/Users";
import Login from "./pages/Login";
import { useState } from "react";
import "./App.css";
import Detail from "./pages/detail/Detail";
import LogoutPage from "./pages/Logout";
import Password from "./pages/detail/Password";
import User from "./pages/User";

setupIonicReact();

const App: React.FC = () => {
  const [isAutheticated, setIsAutheticated] = useState(true);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact>
              <Login />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/users/password">
              <Password />
            </Route>
            <Route path="/users/add">
              <User />
            </Route>
            <Route path="/users/detail/:id" component={Detail}></Route>

            {isAutheticated && (
              <>
                <Route path="/users" exact>
                  <Users />
                </Route>
                <Route path="/logout" exact>
                  <LogoutPage />
                </Route>
              </>
            )}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
