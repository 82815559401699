import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchUser } from "../api/authentication";

const Page: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>("");

  const history = useHistory();

  const login = async () => {
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("loggedInUsername", username);

    if (username && password) {
      const result = await fetchUser({
        username: username,
        password: password,
      });
      localStorage.setItem("token", result.token);
      if (!result.error) {
        history.push("/users");
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>DayClock Admin</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="mt-28 mx-10 md:w-1/2 md:mx-auto">
          <div className="mt-5">
            <IonLabel>Gebruikersnaam</IonLabel>
            <IonInput
              className="border-2 border-blue-200 rounded-md"
              value={username}
              placeholder="Gebruikersnaam"
              onIonChange={(event) =>
                setUsername(event.detail.value ? event.detail.value : "")
              }
            ></IonInput>
          </div>

          <div className="mt-5">
            <IonLabel className="mt-5">Wachtwoord</IonLabel>
            <IonInput
              className="border-2 border-blue-200 rounded-md"
              type="password"
              value={password}
              placeholder="Wachtwoord"
              onIonChange={(event) => {
                setError(null);
                setPassword(event.detail.value ? event.detail.value : "");
              }}
            ></IonInput>
          </div>
          {error && (
            <div className="bg-red-600 text-white rounded-md p-2">
              Gebruikersnaam of wachtwoord is onjuist
            </div>
          )}
          <div className="mt-5">
            <IonButton expand="block" onClick={login}>
              Login
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
